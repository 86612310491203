import countryToISO from '../resources/country_to_iso2.json';
import iso2ioc from '../resources/iso2_to_ioc.json';

export default {
  sanitize: (str) => {
    if(str){
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to   = "aaaaeeeeiiiioooouuuunc------";

      for (var i=0, l=from.length ; i<l ; i++){
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace('.', '-') // replace a dot by a dash
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by a dash
        .replace(/-+/g, '-'); // collapse dashes
    }
    return str;
  },

  sanitize_whitespace: (str) =>{
    if(typeof str === 'string'){
      str = str.replace(/ /g, '');
    }
    return str;
  },

  getMonthName: (date) =>{
    date = new Date(date);
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return monthNames[date.getMonth()];
  },

  strToDate(str){
    var parts = str.match(/(\d+)/g);
    return new Date(parts[2], parts[1]-1, parts[0]);
  },

  eventDate(event){
    if(!event.start_date_obj){
      return;
    }

    if(!event.end_date_obj){
      event.end_date_obj = event.start_date_obj;
    }

    var str;
    var startMonth = this.getMonthName(event.start_date_obj);
    var endMonth = this.getMonthName(event.end_date_obj);
    var startDate = event.start_date_obj.getDate();
    var endDate = event.end_date_obj.getDate();
    var eventYear = event.start_date_obj.getFullYear();

    var termination = {
      1: 'st',
      2: 'nd',
      3: 'rd',
      4: 'th',
    }
    var startDateStr =  (startDate < 3) ? startDate + termination[startDate] : startDate + termination[4];
    var endDateStr =  (endDate < 3) ? endDate + termination[endDate] : endDate + termination[4];

    if(startMonth === endMonth && startDate === endDate){
      str = startMonth + ' ' +startDateStr + ' ' + eventYear;
    }
    else if(startMonth === endMonth){
      str = startMonth + ' ' +startDateStr + ' - ' + endDateStr + ' ' + eventYear;
    } else {
      str = startMonth + ' ' + startDateStr + ' - ' + endMonth + ' ' + endDateStr + ' ' + eventYear;
    }
    return str;

  },

  sortEvents(){

  },

  countryToISO(c){
    c = this.capitalizeWord(c);
    var iso = countryToISO[c];
    var ioc = iso2ioc[iso];
    return ioc ? ioc.toUpperCase() : c ;
  },

  typeAbreviation(t){
    const types = {
      // 'longines global champions tour' : 'LGCT',
      // 'longines FEI nations cup™ jumping': 'FEI Nations Cup™',
      // 'longines FEI world cup™': 'FEI World Cup™',
      // 'artistic gymnastics': 'artistic',
      // 'rhythmic gymnastics': 'rhythmic',

    }
    return types[t] || t ;
  },

  capitalizeWord(text){
    text = text.toLowerCase()
    var t = text.split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

    return t;
  },

}
