import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import YearNavDropdown from './YearNavDropdown';
import $ from 'jquery';

class YearNav extends Component {
  handleToggleDropdown(e){
    $('a.nav-link').removeClass('active');
    $('.year-nav-dropdown-toggle').addClass('active');
    $('a.nav-link').on('click',()=>{
      $('.year-nav-dropdown-toggle').click();
      $('.year-nav-dropdown-toggle').removeClass('active');
      $('a.nav-link').off("click");
    })
  }

  render() {
    const { match, SportInfo } = this.props;
    const url = match.url.replace('/'+match.params.year, '');

    const currentYear = (new Date()).getYear() + 1900;


    const navYears = [currentYear, currentYear -1, currentYear -2];
    var navItems;
    if(SportInfo.slug === 'alpine-skiing'){
       navItems = navYears.map((year, i) => {
        var yearScaleClassName ='';
        var navLinkUrl = (year === currentYear) ? url : url + '/'+ year;
        return (
          <li key={i} className="nav-item">
            <NavLink exact={true}  to={ navLinkUrl } className={"nav-link " + yearScaleClassName}>
             <span>Season</span> {year -1}/{year}
            </NavLink>
          </li>
        );
      });
    } else{
      navItems = navYears.map((year, i) => {
        var yearScaleClassName ='';
        var navLinkUrl = (year === currentYear) ? url : url + '/'+ year;
       return (
         <li key={i} className="nav-item">
           <NavLink exact={true} to={ navLinkUrl} className={"nav-link " + yearScaleClassName}>
            <span>Season</span> {year}
           </NavLink>
         </li>
       );
     });
    }


    return (
            <nav id="year-nav">
              <ul className="nav justify-content-center">
                {navItems}
                <li className="nav-item nav-item-has-dropdown" onClick={(e)=>{this.handleToggleDropdown(e)}}>
                  <button className="year-nav-dropdown-toggle" type="button" data-toggle="collapse" data-target="#years-collapse" aria-expanded="false">
                    <ion-icon name="more"></ion-icon>
                  </button>
                </li>
              </ul>

              <div className="collapse-wrapper">
                <div id="years-collapse" className="collapse" >
                    <YearNavDropdown match={match} SportInfo={SportInfo} />
                </div>
              </div>
           </nav>
    );
  }
}

export default YearNav
