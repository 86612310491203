import * as actionTypes from '../actionTypes/equestrianActionTypes';
import utils from '../../helpers/utils';

const fetchEquestrianListing = year => ((dispatch) => {
    const url = 'https://results.hippo-server.net/api/jsonGetCalendar.php?year=' + year ;
    dispatch(fetchEquestrianListingBegin(year));
    return fetch(url)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {

        json.forEach((event) =>{
          event.id = utils.sanitize(event.name + '-' + event.city );
          event.sport = 'equestrian';
          event.start_date_obj = utils.strToDate(event.start_date);
          event.end_date_obj = utils.strToDate(event.end_date);
          event.month = utils.getMonthName(event.start_date_obj);
          event.end_month = utils.getMonthName(event.end_date_obj);
          event.city = event.city.replace(/ $/,"");

          if(event.logo_url){
            event.logo_url = event.logo_url.replace('http:','https:');
          }

        })

        //sort by start_date
        json.sort(function(a,b){
          return new Date(b.start_date_obj) - new Date(a.start_date_obj);
        })

        const newListing = {};
        newListing[year] = json;
        dispatch(fetchEquestrianListingSuccess(newListing));
        return newListing;
      })
      .catch(error => dispatch(fetchEquestrianListingError(error)))
  }
)

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}


const fetchEquestrianListingBegin = year => ({ type: actionTypes.FETCH_EQUESTRIAN_LISTING_BEGIN, year });
const fetchEquestrianListingSuccess = payload => ({ type: actionTypes.FETCH_EQUESTRIAN_LISTING_SUCCESS, payload });
const fetchEquestrianListingError = error => ({ type: actionTypes.FETCH_EQUESTRIAN_LISTING_ERROR, error });

export default {
  fetchEquestrianListing,
  fetchEquestrianListingBegin,
  fetchEquestrianListingSuccess,
  fetchEquestrianListingError
}
