import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import $ from 'jquery';
import EventResult from '../components/EventResult';
import dataProcessor from '../dataProcessors/gymasticsProcessor';

const SportInfo = {
  sectionName: 'Gymnastics',
  slug: 'gymnastics'
}

const mapStateToProps = state => ({
    events: state.events.gymnastics
  }
)

class EventResultCr extends Component {
  constructor(props){
    super(props);
    console.log('new EventResultCr');
    this.state = {
      loading: true,
      event_data: null,
    };
  }

  getEventNameFormUrl(url){
    var name;
    name = decodeURI(url.replace(/-/g,' '));
    return name;

  }

  loadProviderData(event){

    if(!event.event_url){
      return;
    }

    const { match } = this.props;
    const { year, eventId } = match.params;
    const reactUrlForEvent = '/gymnastics/' + year + '/' + eventId;
    if(match.path === '/gymnastics/:year/:eventId' && !event.hosted_by_provider){
      var event_url = event.event_url;
      event_url = event_url.replace('https://gym.longinestiming.com/','');
      event_url = event_url.replace('http://gym.longinestiming.com/','');
      $.get('http://gym.longinestiming.com/' + event_url)
      .done((data)=>{
       data = dataProcessor.processData(data, event, reactUrlForEvent);
       this.setState({
         loading : false,
         event_data: data
       });
     }).fail(()=>{
       console.log('Error while fetching data');
     });
    }
  }

  componentDidMount(){
    // only work in redux events of the year has been fetch before mounting
    this.loadProviderData(this.getTargetEvent());
    window.scrollTo(0,0)
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if( match.url !== prevProps.match.url){
      this.loadProviderData(this.getTargetEvent());
    }
  }

  getTargetEvent(){
    const { match, events } = this.props;
    const year = match.params.year;
    const eventListing = events[year] || [];
    var event = eventListing.find(({ id }) => id === match.params.eventId) || {};
    return event;
  }

  render() {
    const { history, match } = this.props;
    const { event_data } = this.state;
    const event = this.getTargetEvent();

    if(!event_data){
      // reload if this component has been mounted before the redux store mapping
      this.loadProviderData(event);
    }

    window.ga('send', 'pageview', match.url);

    return (
      <EventResult SportInfo={SportInfo} history={history} event={event} event_data={event_data} />
    );
  }
}

const EventResultDecorated = withRouter(props => <EventResultCr {...props}/>);
const EventResultDecoratedRx = connect(mapStateToProps, null)(EventResultDecorated);

export default EventResultDecoratedRx
