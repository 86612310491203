import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Select from 'react-select'


var navItems;

class YearNav extends Component {
  constructor(props){
    super(props);

    const { match, SportInfo } = this.props;
    const url = match.url.replace('/'+match.params.year, '');
    const currentYear = (new Date()).getYear() + 1900;
    const navYears = [currentYear - 3, currentYear - 4];

    if(SportInfo.slug === 'alpine-skiing'){
       navItems = navYears.map((year, i) => {
        return (
          { value: url + '/'+ year, label: 'Season ' + (year-1) +'/'+year }
        );
      });
    } else{
      navItems = navYears.map((year, i) => {
       return (
         { value: url + '/'+ year, label: 'Season ' + year }
       );
     });
    }

    var selectedYear =  (match.url.match(/\/[0-9]{4}/)) ? match.url.match(/\/[0-9]{4}/)[0].replace('/','') : currentYear ;
    var selectedNavItem;
    navItems.forEach((item)=>{
      var re = new RegExp(selectedYear,"g");
      if(item.value.match(re)){
        selectedNavItem = item;
      }
    })

    this.state = {
      currentNavItem: selectedNavItem,
    }
  }
  handleChange(e){
    this.setState({
      currentNavItem: e,
    })
  }
  render() {
    const { match } = this.props;

    const currentYear = (new Date()).getYear() + 1900;
    var selectedYear =  (match.url.match(/\/[0-9]{4}/)) ? match.url.match(/\/[0-9]{4}/)[0].replace('/','') : currentYear ;


    var selectedNavItem;
    navItems.forEach((item)=>{
      var re = new RegExp(selectedYear,"g");
      if(item.value.match(re)){
        selectedNavItem = item;
      }
    })

    return (
            <div id="year-nav-dropdown">
            <Select classNamePrefix="filter" placeholder="SELECT A YEAR" defaultValue={null} value={selectedNavItem}  options={navItems} onChange={(e)=>{this.handleChange(e)}} />
            { this.state.currentNavItem &&
              <Redirect to={this.state.currentNavItem.value} />
            }
         </div>
    );
  }
}

export default YearNav
