import appConfig from '../appConfig';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import $ from 'jquery';
import utils from '../helpers/utils';

const currentYear = (new Date()).getYear() + 1900;
const mapStateToProps = state => ({
    events: [
      ...state.events.equestrian[currentYear],
      ...state.events.alpineskiing[currentYear],
      ...state.events.gymnastics[currentYear],
    ],

    // events: {
    //   equestrian: state.events.equestrian,
    //   alpineskiing:state.events.alpineskiing,
    //   gymnastics: state.events.gymnastics
    // }

    // events: state.events.equestrian[currentYear]
  }
)

class ScheduledCr extends Component {
  constructor(props){
    super(props);
    this.articles = [];
  }
  
  render() {
    var { title, events } = this.props;

    if(events.length === 0){
      return null;
    }

    var today = (appConfig.emulated_date) ? appConfig.emulated_date : (new Date()) ;
    today = new Date(today.getMonth()+ 1 + '/' + today.getDate() + '/' +currentYear);
    var slickSettings = {
      autoplay: true,
      dots: true,
      vertical: false,
      verticalSwiping: false,
      swipeToSlide: true,
      touchThreshold:10,
      infinite: true,
      autoplaySpeed: 3000,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 1000,
      beforeChange: (event, slick, currentSlide, nextSlide) => {
        const $items = $('.event-scheduled-item h3');
        $items.each((i, el)=>{

          var wordArray = el.innerHTML.split(' ');
          while(el.scrollHeight > el.offsetHeight) {
              wordArray.pop();
              el.innerHTML = wordArray.join(' ') + '...';
           }
        })
      },
    };
    var className;
    switch (title) {
      case 'Past':
        className = 'past';
        events = events.filter((event)=>{
          return today > event.end_date_obj && (event.canceled === false || event.canceled === "false")
        })
        events = events.sort(function(a,b){
          return b.start_date_obj - a.start_date_obj;
        })
        events = events.slice(0, 5);
        slickSettings.autoplaySpeed = 4200;
        break;
      case 'Current':
        className = 'current';
        events = events.filter((event)=>{
          return ((today <= event.end_date_obj) && (today >= event.start_date_obj)) && (event.canceled === false || event.canceled === "false")
        })
        events = events.sort(function(a,b){
          return a.start_date_obj - b.start_date_obj;
        })
        slickSettings.autoplaySpeed = 4000;
        break;
      case 'Upcoming':
        className = 'upcoming';
        events = events.filter((event)=>{
          // console.log(event.start_date_obj);
          return today < event.start_date_obj  && (event.canceled === false || event.canceled === "false")
        })
        events = events.sort(function(a,b){
          return a.start_date_obj - b.start_date_obj;
        })
        events = events.slice(0, 5);
        slickSettings.autoplaySpeed = 4500;
        break;
      default:
    }




    var items = events.map((el, i) => {
      const sportName = el.sport.replace('-',' ');
      const eventDateStr = utils.eventDate(el);
      return (
        <div ref={(article)=>{this.articles["event-"+el.id] = article}} key={"event-" + el.id} className={"event-scheduled-item " + el.sport}>

            <div className="event-scheduled-inner">

              <div className="event-info">
                <h4>{sportName}</h4>
                <h3>

                  { (!el.hosted_by_provider) &&
                  <Link to={ '/' + el.sport + '/' + currentYear + '/' + el.id}>
                    {el.name}
                  </Link>
                  }

                  { (el.hosted_by_provider && sportName === 'gymnastics') &&
                  <a href={"https://gym.longinestiming.com" + el.event_url}>
                    {el.name}
                  </a>
                  }
                </h3>
                <ul className="event-details list-unstyled">
                  <li>
                    <span className="icon" >
                      <ion-icon name="pin"></ion-icon>
                    </span>
                    {el.city}, {el.ioc_code}
                  </li>
                  <li>
                    <span className="icon" >
                      <ion-icon name="time"></ion-icon>
                    </span>
                    { eventDateStr }
                  </li>
                </ul>

                { (!el.hosted_by_provider) &&
                <Link className="link-to-event" to={ '/' + el.sport + '/' + currentYear + '/' + el.id}>
                  View event
                </Link>
                }

                { (el.hosted_by_provider && sportName === 'gymnastics') &&
                <a className="link-to-event" href={"https://gym.longinestiming.com" + el.event_url}>
                  View event
                </a>
                }
              </div>

            </div>
        </div>
      )})

    if(events.length > 0)
      return (
        <div className={"scheduled " + className}>
          <span className="scheduled-title">{title}</span>

          <div className="scheduled-listing">
            <Slider {...slickSettings}>
                {items}
            </Slider>
          </div>
        </div>
      );
    else
      return null
  }
}

const ScheduledCrRx = connect(mapStateToProps, null)(ScheduledCr);

export default ScheduledCrRx
