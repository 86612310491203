import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import Loadable from 'react-loadable';

import Homepage from '../pages/Homepage';
import SportHistoryPage from '../pages/SportHistoryPage';
import AlpineSkiingCr from '../containers/AlpineSkiingCr';
import EquestrianCr from '../containers/EquestrianCr';
import GymnasticsCr from '../containers/GymnasticsCr';

export default () => (
  <Switch>
    <Route exact path="/" component={Homepage} />
    <Route path="/sport-History" component={SportHistoryPage} />
    <Route path="/alpine-skiing/:year?" component={AlpineSkiingCr} />
    <Route path="/equestrian/lite/:year?" component={EquestrianCr} />
    <Route path="/equestrian/:year?" component={EquestrianCr} />
    <Route path="/gymnastics/:year?" component={GymnasticsCr} />
  </Switch>
)
