import appConfig from '../appConfig';
import React, { Component } from 'react';
import { connect } from "react-redux";
import Select from 'react-select'
import utils from '../helpers/utils'
import appActions from '../redux/actions/appActions';

import alpineTypes from '../resources/alpineTypes.json';

// const mapStateToProps = state => {
//   return {
//     filersState: state.app.filters,
//   };
// };
// const mapDispatchToProps = dispatch => ({
//     saveFilters: (filtersState) => { dispatch(appActions.saveFilters(filtersState))},
//     resetFilters: () => { dispatch(appActions.resetFilters())},
//   }
// )
class EventCalendarFilters extends Component {
  constructor(props){
    super(props);

    this.state = {
      collapse: true,
    }

    // if(this.props.savedFiltersState){
    //   this.state = {
    //     collapse: true,
    //     countryFilterState: this.props.savedFiltersState.countryFilterState,
    //     monthFilterState: this.props.savedFiltersState.monthFilterState,
    //     typeFilterState: this.props.savedFiltersState.typeFilterState,
    //     genderFilterState: this.props.savedFiltersState.genderFilterState,
    //   }
    // } else {
    //   this.state = {
    //     collapse: true,
    //     countryFilterState: null,
    //     monthFilterState: null,
    //     typeFilterState: null,
    //     genderFilterState: null,
    //   }
    // }

  }
  monthPreselection(){
    // console.log(this.props.filersState.monthFilterState, 'monthPreselection - monthFilterState');
    //if(!this.props.filersState.monthFilterState){
      const { months, match } = this.props;

      const currentYear = ((new Date()).getYear() + 1900).toString();
      if((currentYear === match.params.year) || !match.params.year){
        const today = (appConfig.emulated_date) ? appConfig.emulated_date : (new Date()) ;
        const currentMonth = utils.getMonthName(today);
        var item = {
          value: currentMonth,
          label: currentMonth
        }

        if(months.indexOf(item.label) >= 0 ){
          console.log('month preselection to => ' + item.value)
          this.handleMonthChange(item);
        } else {
          console.log('month preselection to => MONTH NOT FOUND');
        }
      }
    }
  // }
  componentDidMount(){
    // console.log('EventCalendarFilters did mount');
    this.monthPreselection();
  }
  handleResetAllFilters(){
    this.props.handleResetAll();
  }
  handleCountryChange(e){
    this.props.handleCountryChange(e);
  }
  handleMonthChange(e){
    this.props.handleMonthChange(e);
  }

  handleTypeChange(e){
    this.props.handleTypeChange(e);
  }
  handleGenderChange(e){
    this.props.handleGenderChange(e);
  }
  toggleCollapse(){
    this.setState({
      collapse: !this.state.collapse
    });
  }

  render() {
    // this.monthPreselection();
    const { countryFilterState, monthFilterState, typeFilterState, genderFilterState } = this.props.filersState;
    var { genders,countries, months, types, SportInfo, match } = this.props;

    // countries
    const genderFiltersItems = genders.map((el, i) => {
      return (
        { value: el, label: el }
      )
    })
    genderFiltersItems.sort(function(a, b){
      if(a.value < b.value) { return -1; }
      if(a.value > b.value) { return 1; }
      return 0;
    })

    // countries
    const countryFiltersItems = countries.map((el, i) => {
      var label = el.replace('-',' ');
      return (
        { value: el, label: label }
      )
    })
    countryFiltersItems.sort(function(a, b){
      if(a.value < b.value) { return -1; }
      if(a.value > b.value) { return 1; }
      return 0;
    })

    // months
    var monthFiltersItems = months.map((el, i) => {
      return (
        { value: el, label: el }
      )
    })
    var monthsOrder;
    if(SportInfo.slug === 'alpine-skiing'){
      monthsOrder = {
        'September': 1,
         'October': 2,
         'November': 3,
         'December': 4,
        'January': 5,
        'February': 6,
        'March': 7,
        'April': 8,
        'May': 9,
        'June': 10,
        'July': 11,
        'August': 12,
      };
    } else {
      monthsOrder = {
        'January': 1,
        'February': 2,
        'March': 3,
        'April': 4,
        'May': 5,
        'June': 6,
        'July': 7,
        'August': 8,
        'September': 9,
         'October': 10,
         'November': 11,
         'December': 12
      };
    }

    monthFiltersItems.sort(function(a, b){
      if(monthsOrder[a.label] < monthsOrder[b.label]) { return -1; }
      if(monthsOrder[a.label] > monthsOrder[b.label]) { return 1; }
      return 0;
    });

    // add year to monthfilter label
    if(SportInfo.slug === 'alpine-skiing'){
      const currentYear = (new Date()).getYear() + 1900;
      var y = match.params.year ? parseInt(match.params.year) : currentYear ;
      var monthsInPreviousSeason = [
        'September',
         'October',
         'November',
         'December',
      ];
      monthFiltersItems.forEach((f)=>{
        if(monthsInPreviousSeason.indexOf(f.label) > 0){
          f.label += ' ' + (y-1).toString();
        } else {
          f.label += ' ' + (y).toString();
        }
      })
    }


    // months
    const typeFiltersItems = types.map((el, i) => {
      var label = alpineTypes[el] || el;
      label = label.replace('fei', 'FEI');

      return (
        { value: el, label: label }
      )
    })
    typeFiltersItems.sort(function(a, b){
      if(a.value < b.value) { return -1; }
      if(a.value > b.value) { return 1; }
      return 0;
    })

    var isNoFilterApplied = (genderFilterState === null)
      && (countryFilterState === null)
      && (monthFilterState === null)
      && (typeFilterState === null);

    return (
      <div className="filters container-fluid">
        <div className="filters-toggle-wrapper ">
          <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#filters-collapse" aria-expanded="false" aria-controls="collapseExample" onClick={()=>{this.toggleCollapse()}}  >
            <span>Filters</span>

            {this.state.collapse &&
              <span className="icon"><ion-icon name="ios-arrow-down"></ion-icon></span>
            }
            {!this.state.collapse &&
              <span className="icon"><ion-icon name="ios-arrow-up"></ion-icon></span>
            }
          </button>
          { !isNoFilterApplied &&
            <div className="reset-all-wrapper">

              <button className="btn btn-default" onClick={(e)=>{this.handleResetAllFilters(e)}} >
                Reset <ion-icon name="refresh"></ion-icon>
              </button>

            </div>
          }
          <div className="current-filters-state">
            {genderFilterState && this.state.collapse &&
              <span className="badge badge-primary" onClick={()=>{this.handleGenderChange(null)}}>
                {genderFilterState.label}
                <span className="reset-current-filter">
                  <ion-icon name="close"></ion-icon>
                </span>
              </span>
            }
            {typeFilterState && this.state.collapse &&
              <span className="badge badge-primary" onClick={()=>{this.handleTypeChange(null)}}>
                {typeFilterState.label}
                <span className="reset-current-filter">
                  <ion-icon name="close"></ion-icon>
                </span>
              </span>
            }
            {monthFilterState && this.state.collapse &&
              <span className="badge badge-primary" onClick={()=>{this.handleMonthChange(null)}}>
                {monthFilterState.label}
                <span className="reset-current-filter">
                  <ion-icon name="close"></ion-icon>
                </span>
              </span>
            }
            {countryFilterState && this.state.collapse &&
              <span className="badge badge-primary" onClick={()=>{this.handleCountryChange(null)}}>
                {utils.countryToISO(countryFilterState.label)}
                <span className="reset-current-filter">
                  <ion-icon name="close"></ion-icon>
                </span>
              </span>
            }
          </div>
        </div>
        <div id="filters-collapse" className="collapse" >
            <div className="row">
              { (SportInfo.slug === 'alpine-skiing') &&
                <div className="col-lg">
                  <Select classNamePrefix="filter" placeholder="DISCIPLINE GENDER" defaultValue={null} value={genderFilterState}  options={genderFiltersItems} onChange={(e)=>{this.handleGenderChange(e)}} isClearable="true" isSearchable={false} />
                </div>
              }
              <div className="col-lg">
                <Select classNamePrefix="filter" placeholder="ALL TYPES" defaultValue={null} value={typeFilterState}  options={typeFiltersItems} onChange={(e)=>{this.handleTypeChange(e)}} isClearable="true" isSearchable={false} />
              </div>
              <div className="col-lg">
                <Select classNamePrefix="filter" placeholder="ALL MONTHS" defaultValue={null} value={monthFilterState} options={monthFiltersItems} onChange={(e)=>{this.handleMonthChange(e)}} isClearable="true" isSearchable={false} />
              </div>
              <div className="col-lg">
                <Select classNamePrefix="filter" placeholder="ALL COUNTRIES" defaultValue={null} value={countryFilterState} options={countryFiltersItems} onChange={(e)=>{this.handleCountryChange(e)}} isClearable="true" isSearchable={false} />
              </div>
            </div>


        </div>


      </div>
    );
  }
}

// const EventCalendarFiltersRx = connect(mapStateToProps, mapDispatchToProps)(EventCalendarFilters);

export default EventCalendarFilters
