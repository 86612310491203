import $ from 'jquery';

var gymnasticsProcessor = {
  processData(data, event, currentUrl){
    var $data = $(data);

    //process will come here
    // $data = this.removeWrappingContainer($data);

    $data = this.preventUrlError($data);
    $data = this.tabs($data);
    $data = this.liveTiming($data);
    this.removeRowFromHeader($data);
    $data = this.targetBlank($data);

    $data = this.removeHeaderWrapper($data);
    $data = this.removeWrappingContainer($data);
    // $data = this.keepOnlyWrapper($data);

    // wrap all this content before return it
    var $dataWrapper = $('<div></div>');
    $dataWrapper.append($data);
    return $dataWrapper;
  },
  preventUrlError($data){
    console.log($data)
    console.log($data.find('body'));
    if($data.length > 2){
      $data = $('<div id="content-wrapper"><p class="data-error">Error while processing data. Please come back later</p></div>');
    }
    return $data;
  },
  // keepOnlyWrapper($data){
  //   console.log($data);
  //   var $content = $data.find('#content-wrapper');
  //   $data.html($content);
  //   return $data;
  // },
  removeRowFromHeader($data){
    var $header = $data.find('#header-wrapper') ;
    var $pageheader = $header.find('.row .page-header');
    $header.html($pageheader);
    return $data;
  },

  removeWrappingContainer($data){
    var $container = $data.find('#content');
    $data.html($container.html());
    return $data;
  },

  removeHeaderWrapper($data){
    $data.find('#header-wrapper').remove();
    return $data;
  },

  targetBlank($data){
    var links = $data.find('a.dl-link')
    links.attr('target','_blank');

    // $data.find('#content-wrapper').append('<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist"><li class="nav-item"><a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Home</a></li><li class="nav-item"><a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Profile</a></li><li class="nav-item"><a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Contact</a></li></ul><div class="tab-content" id="pills-tabContent"><div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">home</div><div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">profil</div><div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"></div></div>')

    return $data;
  },

  liveTiming($data){
    var $live = $data.find('#header-wrapper a') ;
    $live.addClass('live-timing-link');
    var $contentWrapper = $data.find('#content-wrapper');
    $contentWrapper.prepend($live);
    return $data;
  },

  tabs($data){
    var $contentWrapper = $data.find('#content-wrapper');

    var $titles = $contentWrapper.find('h2');
    var $first = $contentWrapper.find('.event-result').first();
    var $contents = $contentWrapper.find('.event-result').slice( 1 );

    //build boostrap tabs
    var $tabNavs = $('<ul class="nav nav-pills nav-fill" id="nav-tab" role="tablist"></ul>');
    var i = 0;
    $titles.each((index, el)=>{
      var active = '';
      if(i === 0){
        active = 'active';
      }
      var $el = $(el);
      var $link = $(' <li class="nav-item"><a class="nav-link '+active+'" id="nav-gym-tab" data-toggle="tab" href="#nav-'+i+'" role="tab" aria-controls="nav-home" aria-selected="true">'+$el.html()+'</a></li>');
      $tabNavs.append($link);
      i++;
    });

    var $tabsContents= $('<div class="tab-content" id="nav-tabContent"></div>');
    i = 0;
    $contents.each((index, el)=>{
      var active = '';
      if(i === 0){
        active = 'show active';
      }
      var $el = $(el);
      var $content = $('<div class="tab-pane fade '+active+'" id="nav-'+i+'" role="tabpanel" aria-labelledby="nav-gym-tab"></div>');
      $content.html($el.html());
      $tabsContents.append($content);
      i++;
    });
    $tabNavs.wrap('<nav></nav>');


    var $eventResult = $('<section class="event-result"></section>');
    $eventResult.html($tabNavs);
    $eventResult.append($tabsContents);

    $contentWrapper.html($eventResult);

    $contentWrapper.prepend($first);

    return $data;
  },

}

export default gymnasticsProcessor
