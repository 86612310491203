import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import $ from 'jquery';
import EventResult from '../components/EventResult';
import appActions from '../redux/actions/appActions';
import dataProcessor from '../dataProcessors/equestrianProcessor';

const SportInfo = {
  sectionName : 'Equestrian',
  slug: 'equestrian'
}

const mapStateToProps = state => ({
    events: state.events.equestrian
  }
)

const mapDispatchToProps = dispatch => ({
    setAppSection: () => { dispatch(appActions.setAppSection({appSection: 'equestrian lite'}))},
  }
)

class EventResultCr extends Component {
  constructor(props){
    super(props);
    console.log('new EventResultCr');
    this.state = {
      loading: true,
      event_data: null,
    };
  }

  getEventNameFormUrl(url){
    var name;
    name = decodeURI(url.replace(/-/g,' '));
    return name;

  }

  loadProviderData(event){
    const { match } = this.props;
    const { year, eventId } = match.params;
    const reactUrlForEvent = '/equestrian/' + year + '/' + eventId;

    if(match.path === '/equestrian/lite/:year/:eventId' || match.path === '/equestrian/lite/:year/:eventId/*'){
      this.props.setAppSection();
    }

    if(!event.event_url){
      return;
    }

    if(match.path === '/equestrian/:year/:eventId' || match.path === '/equestrian/lite/:year/:eventId'){
      let urlToDownload = event.event_url.replace('http:', 'https:');
      $.get(urlToDownload)
      .done((data)=>{
       // $('.ajax-wrapper').append(data);
       data = dataProcessor.processData(data, event, reactUrlForEvent);
       this.setState({
         loading : false,
         event_data: data
       });
      })
      .fail(()=>{
        console.log('Error while fetching data');
      });

    } else if (match.path === '/equestrian/:year/:eventId/*' || match.path === '/equestrian/lite/:year/:eventId/*') {
      var event_root_url_slipped =  event.event_url.split('/')
      var event_root_url = event_root_url_slipped.slice(0, event_root_url_slipped.length - 1).join('/') + '/' ;
      event_root_url = event_root_url.replace('http://results.hippodata.de','');

      const url =  event_root_url + match.params[0];
      // const url =  event_root_url + match.params.subs;
      $.get('https://results.hippodata.de' + url).done((data)=>{
       // $('.ajax-wrapper').append(data);
       data = dataProcessor.processData(data, event, reactUrlForEvent);
       this.setState({
         loading : false,
         event_data: data
       });
      });
    } else {

    }
  }

  componentDidMount(){
    // only work in redux events of the year has been fetch before mounting
    this.loadProviderData(this.getTargetEvent());
    window.scrollTo(0,0)
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if( match.url !== prevProps.match.url){
      this.loadProviderData(this.getTargetEvent());
    }
  }

  getTargetEvent(){
    const { match, events } = this.props;
    const year = match.params.year;
    const eventListing = events[year] || [];
    var event = eventListing.find(({ id }) => id === match.params.eventId) || {};
    return event;
  }

  render() {
    const { history, match } = this.props;
    const { event_data } = this.state;
    const event = this.getTargetEvent();

    if(!event_data){
      // reload if this component has been mounted before the redux store mapping
      this.loadProviderData(event);
    }

    // $(event_data).find('#content-wrapper').addClass('live');

    var css = $(event_data).find('link');
    $('section.event-detail').prepend(css);

    // handleLIve
    if($(event_data).find('#content-wrapper').hasClass('live')){
      setTimeout(()=>{
        console.log('live reload');
        this.loadProviderData(event);
      },20000);
    }

    window.ga('send', 'pageview', match.url);

    return (
      <EventResult SportInfo={SportInfo} history={history} event={event} event_data={event_data} />
    );
  }
}

const EventResultDecorated = withRouter(props => <EventResultCr {...props}/>);
const EventResultDecoratedRx = connect(mapStateToProps, mapDispatchToProps)(EventResultDecorated);

export default EventResultDecoratedRx
