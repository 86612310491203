import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import Scheduled from '../containers/ScheduledCr';
import appActions from '../redux/actions/appActions';
import equestrianActions from '../redux/actions/equestrianActions';
import alpineskiingActions from '../redux/actions/alpineskiingActions';
import gymnasticsActions from '../redux/actions/gymnasticsActions';
import AppLink from '../components/AppLink';


const SportHistory = () => (
  <div className="longines-sport-history">
    <Link to="/sport-history">
      <div className="longines-sport-history-inner">
        <div className="row">
          <div className="box-content col-md-6">
            <h3>Longines Sport History</h3>
            Longines’ long-lasting involvement in sports timekeeping started in 1878 with the first chronograph manufactured by the brand.
            Building on its in-house expertise, Longines has gradually developed a special relationship with the world of sport. It can count now many years of experience as official timekeeper of world championships, and as partner of international sports federations.
          </div>
          <div className="box-watch col-md-6">
            <iframe title="e-clock" src="https://www.longinestiming.com/clock/200x200/" width="200" height="200" scrolling="no" frameBorder="0" />
          </div>
        </div>
      </div>
    </Link>

  </div>
);

const mapDispatchToProps = dispatch => ({
    setAppSection: () => { dispatch(appActions.setAppSection({appSection: 'homepage'}))},
    appInLoading: (bool) => { dispatch(appActions.appInLoading(bool))},
    fetchAlpineskiingListing: (year) => { dispatch(alpineskiingActions.fetchAlpineskiingListing(year))},
    fetchEquestrianListing: (year) => { dispatch(equestrianActions.fetchEquestrianListing(year))},
    fetchGymnasticsListing: (year) => { dispatch(gymnasticsActions.fetchGymnasticsListing(year))},
  }
)

class Homepage extends Component {
  componentWillMount(){
    this.fetchListingFromProvider();
  }
  componentDidMount(){
    this.props.setAppSection();
    setTimeout(()=>{
      this.props.appInLoading(false);
    }, 900);
  }
  componentDidUpdate(prevProps) {
    setTimeout(()=>{
      this.props.appInLoading(false);
    }, 900);
  }
  fetchListingFromProvider(){
    const year = (new Date()).getFullYear();
    this.props.fetchEquestrianListing(year);
    this.props.fetchAlpineskiingListing(year);
    this.props.fetchGymnasticsListing(year);
  }
  render() {
    return (
      <div className="page">
        <div className="hero">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col text-center">
              <h2>
                <AppLink to="/alpine-skiing">
                  Alpine Skiing
                </AppLink>
              </h2>
              </div>
              <div className="col text-center">
                <h2>
                  <AppLink to="/equestrian">
                    Equestrian
                  </AppLink>
                </h2>
              </div>
              <div className="col text-center">
                <h2>
                  <AppLink to="/gymnastics">
                    Gymnastics
                  </AppLink>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <Scheduled title="Current"/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Scheduled title="Past"/>
            </div>
            <div className="col-md-6">
              <Scheduled title="Upcoming" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <SportHistory />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const HomepageRx = connect(null, mapDispatchToProps)(Homepage);

export default HomepageRx
