import $ from 'jquery';
import ioc2iso from '../resources/ioc_to_iso2.json';

var equestrianProcessor = {
  processData(data, event, currentUrl){
    var $data = $(data);

    //process
    this.updateLink($data, event, currentUrl);
    this.updateSrc($data, event, currentUrl);
    $data = this.content($data, event, currentUrl);
    $data = this.targetBlank($data);
    $data = this.responsiveTable($data);
    // $data = this.flagIcon($data);
    $data = this.tableResult($data);
    this.tabs($data, event, currentUrl);

    // wrap all this content before return it
    var $dataWrapper = $('<div></div>');
    $dataWrapper.append($data);
    return $dataWrapper;
  },

  updateSrc($data, event, currentUrl){
    $data.find('link').each((i, e)=>{
      var $e = $(e);
      var src = $e.attr('href');
      src = src.replace('http:','https:');
      $e.attr('href', src);
    });

    $data.find('img').each((i, e)=>{
      var $e = $(e);
      var src = $e.attr('src');
      src = src.replace('http:','https:');
      $e.attr('src', src);
    });

  },

  updateLink($data, event, currentUrl){

    var event_root_url_slipped =  event.event_url.split('/')
    var event_root_url = event_root_url_slipped.slice(0, event_root_url_slipped.length - 1).join('/') + '/' ;
    event_root_url = event_root_url.replace('http://results.hippodata.de','');
    event_root_url = event_root_url.replace('https://results.hippodata.de','');

    $data.find('a').each((i, e)=>{

      var $e = $(e);
      if(!$e.hasClass('dl-link') && !$e.hasClass('external-link')){
        var href = $e.attr('href').replace(event_root_url,'');
        href = href.replace('http://results.hippodata.de','');
        href = href.replace('https://results.hippodata.de','');

        var newHref = currentUrl + '/' + href ;
        $e.attr('href', newHref);
      }
    });
  },

  targetBlank($data){
    var links = $data.find('a.dl-link')
    links.attr('target','_blank');

    return $data;
  },

  responsiveTable($data){
    var tables = $data.find('table');
    tables.wrap('<div class="table-responsive"></div>');

    return $data;
  },

  content($data){
    var $row = $('<div class="row"></div>');
    var $main = $('<div class="col-sm-12 event-content"></div>');
    $main.append($data);
    $row.append($main);
    return $row;
  },

  tabs($data){
    var $tabs = $data.find('ul.nav-tabs');
    $tabs.removeClass('list');
    $tabs.removeClass('nav-tabs').addClass('nav-pills nav-fill');
    $tabs.find('li').addClass('nav-item');
    $tabs.find('li').find('a').addClass('nav-link');
    $tabs.find('li.active').removeClass('active').find('a').addClass('active');
    return $data;
  },

  tableResult($data){
    var tables = $data.find('table');
    tables.each((i, e)=>{
      var $th;
      var $tr;

      var $e = $(e);
      if(true){
        $th = $e.find('th');
        $tr = $e.find('tr');
        //
        // $th.first().html('<span class="visible-xs hidden-sm hidden-md hidden-lg">Rk</span><span class="hidden-xs">Rank</span>')
        // $th.first().addClass('rank');
        //
        // $th.eq(1).addClass('bib');
        //
        // if(!$e.hasClass('table-rankings')){
        //   $th.eq(3).html('Time');
        //   $th.eq(3).addClass('time');
        //   $th.eq(5).html('<span class="visible-xs hidden-sm hidden-md hidden-lg">WC Pts</span><span class="hidden-xs">WC Points</span>');
        //   $th.eq(5).addClass('wc-points');
        // }

        $tr.each((i, e)=>{
          var $e = $(e);
          var $flag_td = $e.find('.hd_flag');
          var $rider_td = $e.find('.hd_ridername');

          var country_ioc = $flag_td.text();
          var country_iso = ioc2iso[country_ioc.toLowerCase()];
          var $flag = $('<span class="flag-icon flag-icon-'+country_iso+'"></span>')
          $rider_td.prepend($flag);

          // var r = $td.first().text();
          // $td.first().wrapInner('<span></span>');
          // $td.first().addClass('rank rank-'+r);
          //
          // $td.eq(1).addClass('bib');
          // $td.eq(3).addClass('time');
          // $td.eq(5).addClass('wc-points');
        });
      }
    })
    return $data;
  },

  flagIcon($data){
    var flags = $data.find('.hd_flag');


    flags.each((i, e)=>{
      var $e = $(e);
      var classNames = $e.attr('class');
      var country_ioc = classNames.replace('flag-icon-', '').replace('flag-icon', '').replace(' ', '');
      var country_iso = ioc2iso[country_ioc.toLowerCase()];
      $e.attr('class', 'flag-icon flag-icon-' + country_iso );
    })

    return $data;
  }

}

export default equestrianProcessor
