import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import EventCalendar from '../components/EventCalendar';
import appActions from '../redux/actions/appActions';
import actions from '../redux/actions/gymnasticsActions';
import GymnasticsEventResultCr from '../containers/GymnasticsEventResultCr';

const SportInfo = {
  sectionName: 'Gymnastics',
  slug: 'gymnastics'
}

const mapStateToProps = state => {
  return {
    events: state.events.gymnastics,
    filters: state.app.filters
   };
};

const mapDispatchToProps = dispatch => ({
    setAppSection: () => { dispatch(appActions.setAppSection({appSection: 'gymnastics'}))},
    resetFilters: () => { dispatch(appActions.resetFilters())},
    saveFilters: (filtersState) => { dispatch(appActions.saveFilters(filtersState))},
    appInLoading: (bool) => { dispatch(appActions.appInLoading(bool))},
    fetchListing: (year) => { dispatch(actions.fetchGymnasticsListing(year))}
  }
)

class EventListingCr extends Component {

  fetchListingFromProvider(){
    const { match } = this.props;
    const year = match.params.year || (new Date()).getFullYear();
    this.props.fetchListing(year);
  }

  componentWillMount(){

    // relaod filtersState from sessionStorage
    if(sessionStorage.getItem('lti:lastVisitedSection') === SportInfo.slug){
      const filtersState = JSON.parse(sessionStorage.getItem('lti:filtersState'));
      this.props.saveFilters(filtersState);
    } else {
      this.props.resetFilters();
    }
    sessionStorage.setItem('lti:lastVisitedSection', SportInfo.slug);
  }

  componentDidMount(){
    this.props.setAppSection();
    this.fetchListingFromProvider();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if(match.params.year !== prevProps.match.params.year){
      this.fetchListingFromProvider();
    }

    setTimeout(()=>{
      this.props.appInLoading(false);
    }, 500);
  }

  render(){
    const { match, history, events} = this.props;
    const year = match.params.year || (new Date()).getFullYear();
    const eventListing =  events[year] || [];

    window.ga('send', 'pageview', match.url);

    return (
      <div className="container">
        <Switch>
          <Route path="/gymnastics/:year/:eventId/*" component={GymnasticsEventResultCr} />
          <Route path="/gymnastics/:year/:eventId" component={GymnasticsEventResultCr} />
          <Route path="/gymnastics/:year?" render={(props) => <EventCalendar {...props} events={eventListing} SportInfo={SportInfo} history={history} />} />
        </Switch>
      </div>
    )
  }
}

const GymnasticsEventListingDecorated = withRouter(props => <EventListingCr {...props}/>);
const GymnasticsEventListingDecoratedRx = connect(mapStateToProps, mapDispatchToProps)(GymnasticsEventListingDecorated);

export default GymnasticsEventListingDecoratedRx
