import * as actionTypes from '../actionTypes/alpineskiingActionTypes';
import utils from '../../helpers/utils';
import appConfig from '../../appConfig';

// ENVIRONNEMENT
let env;
if(appConfig.env === 'preprod'){
  env = 'preprod';
} else {
  env = window.location.href.match(/https:\/\//) ? 'https' : 'production';
}
const environnement = env;

const fetchAlpineskiingListing = year => ((dispatch) => {
    const url = 'https://www.fis-ski.com/DB/services/cors-feeds/json/longines-calendar/AL/' + year + '/?env='+environnement ;
    dispatch(fetchAlpineskiingListingBegin(year));
    return fetch(url)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {

        var cpt = 1;
        json.forEach((event) =>{
          event.id = event.id = utils.sanitize(event.name + '-' + event.city ) + '-' + event.start_date.replace(/\./g,'') + cpt;
          event.sport = 'alpine-skiing';
          event.start_date_obj = utils.strToDate(event.start_date);
          event.end_date_obj = utils.strToDate(event.start_date);
          event.month = utils.getMonthName(event.start_date_obj);
          event.end_month = utils.getMonthName(event.end_date_obj);
          event.city = event.city.replace(/ $/,"");
          cpt++;
        })

        //sort by start_date
        json.sort(function(a,b){
          return new Date(b.start_date_obj) - new Date(a.start_date_obj);
        })

        const newListing = {};
        newListing[year] = json;
        dispatch(fetchAlpineskiingListingSuccess(newListing));
        return newListing;
      })
      .catch(error => dispatch(fetchAlpineskiingListingError(error)))
  }
)

const fetchAlpineskiingRanking = (year, discipline = 'ALL', gender = 'women', type = 'WC') => ((dispatch) => {
    // const url = 'https://www.fis-ski.com/DB/services/cors-feeds/json/longines-calendar/AL/' + year + '/?env=preprod' ;

    const availableRankingDiscipline = ['DH', 'SL', 'GS', 'AC'];
    discipline = (availableRankingDiscipline.indexOf(discipline) > -1) ? discipline : 'ALL';

    const url = 'https://www.fis-ski.com/DB/services/cors-feeds/json/alpine-skiing/standings-full/longines/?discipline-id='+discipline+'&competition-id='+type+'&gender='+gender+'&season=' + year + '&env='+environnement ;
    dispatch(fetchAlpineskiingRankingBegin(year));
    return fetch(url)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        json = json.results;
        var cpt = 1;
        json.forEach((rank) =>{
          rank.discipline = discipline;
          rank.type = type;
          rank.gender = gender;
          cpt++;
        })
        //
        // //sort by start_date
        // json.sort(function(a,b){
        //   return new Date(b.start_date_obj) - new Date(a.start_date_obj);
        // })

        const newListing = {};
        newListing[year +'-'+discipline +'-'+gender +'-'+type] = json;

        // const newListing = {};
        // newListing[year] = {};
        // newListing[year][discipline] = json;

        dispatch(fetchAlpineskiingRankingSuccess(newListing));
        return newListing;
      })
      .catch(error => dispatch(fetchAlpineskiingRankingError(error)))
  }
)

// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}


const fetchAlpineskiingListingBegin = year => ({ type: actionTypes.FETCH_ALPINESKIING_LISTING_BEGIN, year });
const fetchAlpineskiingListingSuccess = payload => ({ type: actionTypes.FETCH_ALPINESKIING_LISTING_SUCCESS, payload });
const fetchAlpineskiingListingError = error => ({ type: actionTypes.FETCH_ALPINESKIING_LISTING_ERROR, error });


const fetchAlpineskiingRankingBegin = year => ({ type: actionTypes.FETCH_ALPINESKIING_RANKING_BEGIN, year });
const fetchAlpineskiingRankingSuccess = payload => ({ type: actionTypes.FETCH_ALPINESKIING_RANKING_SUCCESS, payload });
const fetchAlpineskiingRankingError = error => ({ type: actionTypes.FETCH_ALPINESKIING_RANKING_ERROR, error });

export default {
  fetchAlpineskiingListing,
  fetchAlpineskiingListingBegin,
  fetchAlpineskiingListingSuccess,
  fetchAlpineskiingListingError,

  fetchAlpineskiingRanking,
  fetchAlpineskiingRankingBegin,
  fetchAlpineskiingRankingSuccess,
  fetchAlpineskiingRankingError
}
