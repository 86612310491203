import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { connect } from "react-redux";
import AppNavigation from '../components/AppNavigation';
import SportNavigation from '../components/SportNavigation';
import AppRoutes from '../components/AppRoutes';
import '../assets/sass/app.scss';
import 'bootstrap';
import $ from 'jquery';
import actions from '../redux/actions/equestrianActions';

import urlRewriter from '../helpers/urlRewriter';


const currentYear = (new Date()).getYear() + 1900;

// // Mobile outside click close offcanvas
// var mobileMenuOutsideClick = function() {
//       $(document).click(function (e) {
//       var container = $("#offcanvas, .js-nav-toggle, .mobile-menu a");
//
//       if (!container.is(e.target) && container.has(e.target).length === 0) {
//        // if($(e.target).closest('body.offcanvas').length < 0){
//           if ( $('body').hasClass('offcanvas') ) {
//
//               $('body').removeClass('offcanvas');
//               $('.js-nav-toggle').removeClass('active');
//           }
//       }
//       });
//   };


// Mobile outside click close offcanvas
var mobileMenuClickOnLink = function() {
      $('#offcanvas a').click(function (e) {
        if ( $('body').hasClass('offcanvas') ) {

            $('body').removeClass('offcanvas');
            $('.js-nav-toggle').removeClass('active');
        }
      });
      $('a.navbar-brand').click(function (e) {
        if ( $('body').hasClass('offcanvas') ) {

            $('body').removeClass('offcanvas');
            $('.js-nav-toggle').removeClass('active');
        }
      });
  };


// Offcanvas menu
var offcanvasMenu = function() {

    // $('.app-longines-timing').prepend('<a href="#" class="js-nav-toggle nav-toggle"><i></i></a>');

    // $('#offcanvas .menu-item-has-children').addClass('offcanvas-has-dropdown');
    // $('#offcanvas')
    //     .find('li')
    //     .removeClass('menu-item-has-children');
    //
    // // Hover dropdown menu on mobile
    // $('.offcanvas-has-dropdown').mouseenter(function(){
    //
    //   $(this)
    //         .addClass('active')
    //         .find('ul')
    //         .slideDown(500, 'easeOutExpo');
    // }).mouseleave(function(){
    //
    //     $(this)
    //         .removeClass('active')
    //         .find('ul')
    //         .slideUp(500, 'easeOutExpo');
    // });

    $(window).resize(function(){
        if ( $('body').hasClass('offcanvas') ) {

            $('body').removeClass('offcanvas');
            $('.js-nav-toggle').removeClass('active');

        }
    });
};

var burgerMenu = function() {
    $('body').on('click', '.js-nav-toggle', function(event){
        var $this = $(this);


        if ( $('body').hasClass('offcanvas') ) {
            $('body').removeClass('offcanvas');
        } else {
            $('body').addClass('offcanvas');
        }
        $this.toggleClass('active');
        event.preventDefault();

    });
};


const mapStateToProps = state => {
  return {
    appSection: state.app.appSection,
    appInLoading: state.app.appInLoading,
    events: state.events.equestrian
  };
};

const mapDispatchToProps = dispatch => ({
    fetchEquestrianListing: (year) => { dispatch(actions.fetchEquestrianListing(year))},
  }
)

class AppLonginesTimingV2 extends Component {
  componentDidMount(){
    // mobileMenuOutsideClick();
    mobileMenuClickOnLink();
    offcanvasMenu();
    burgerMenu();
  }

  render() {
    const { appSection, appInLoading, events } = this.props;

    if(window.location.href.match(/\/#!\//)){
      var oldUrl = window.location.href;
      var newUrl = urlRewriter(oldUrl, this.props);
      return null;
    }

    if(!appInLoading){
      $('#loader').delay(200).fadeOut();
    } else {
      $('#loader').delay().show();
    }

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    var browser;
    browser = (isIE) ? 'ie' : '';
    browser = (isEdge) ? 'edge' : '';

    return (
      <Router>
        <div className={'app-longines-timing ' + appSection }>
          <div id="loader" className={"loader " + browser} >
            <div className="loader-inner">
              <div className="loader-logo">
                  <div className="progress">
                    <div className="progress-bar"></div>
                  </div>
              </div>
            </div>
          </div>

          <div id="offcanvas">
            <nav className="mobile-menu">
              <SportNavigation />
              <div className="clock-wrapper">
                <iframe title="e-clock" src="https://www.longinestiming.com/clock/300x300/" width="300" height="300" scrolling="no" frameBorder="0" />
              </div>
            </nav>
            <footer className="footer">
             <div className="container-fluid">
              <div className="row">
              <div className="col-sm order-sm-2">
                  <ul className="footer-nav d-flex list-inline">
                      <li><Link to="/sport-history">Longines Sport History</Link></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Longines"><ion-icon name="logo-facebook"></ion-icon></a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/longines/"><ion-icon name="logo-instagram"></ion-icon></a></li>
                      <li><a target="_blank" rel="noopener noreferrer" href="https://www.longines.com/"><ion-icon name="globe"></ion-icon></a></li>
                  </ul>

              </div>
              <div className="col-sm order-sm-1">
                  <div className="nav copyright">
                    &copy; {currentYear} LONGINES WATCH CO FRANCILLON LTD, <span>ALL RIGHTS RESERVED</span>
                  </div>
              </div>
                </div>
              </div>
            </footer>
          </div>

          <header className="banner">
            <Route path="*" component={AppNavigation} />
          </header>
          <main>
            {/* ROUTES */}
            <AppRoutes  />
          </main>
          <footer className="footer">
           <div className="container">
            <div className="row">
                <div className="col-md order-md-2">
                    <ul className="footer-nav d-flex list-inline">
                        <li><Link to="/sport-history">Longines Sport History</Link></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Longines"><ion-icon name="logo-facebook"></ion-icon></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/longines/"><ion-icon name="logo-instagram"></ion-icon></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.longines.com/"><ion-icon name="globe"></ion-icon></a></li>
                    </ul>
                </div>
                <div className="col-md order-md-1">
                    <div className="nav copyright">
                      &copy; {currentYear} LONGINES WATCH CO FRANCILLON LTD, <span>ALL RIGHTS RESERVED</span>
                    </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </Router>
    );
  }
}

const AppLonginesTimingV2Rx = connect(mapStateToProps, mapDispatchToProps)(AppLonginesTimingV2);

export default AppLonginesTimingV2Rx
